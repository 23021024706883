<template>
  <div class="square-content" id="page-loader">
    <div class="loader-position">
      <h1>
        Configurando tu cuenta
        <span class="dot">.</span>
        <span class="dot">.</span>
        <span class="dot">.</span>
      </h1>
      <div id="container">
        <div id="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  mounted() {},
};
</script>

<style scoped>
.square-content {
  background: linear-gradient(to bottom right, #fff, #fff, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 100vh;
  width: 100vw;
  color: #000;
  position: absolute;
  z-index: 100;
}

.square-content h1 {
  font-size: 32px;
  text-align: center;
}

@keyframes load {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(390px, 0);
  }
}

#bar {
  width: 100px;
  height: 10px;
  background: linear-gradient(to bottom right, #eb0029, #d41837);
  border-radius: 6px;
  /* box-shadow: 0 0 10px #eb0029; */

  animation: load 0.7s infinite alternate ease-in-out;
}

@keyframes dots {
  from {
    color: #eb0029;
    transform: translate(0, -10%);
  }
  to {
    color: white;
    transform: translate(0, 0);
  }
}

.dot {
  display: inline-block;
  font-size: 250%;
}

.dot:nth-child(1) {
  animation: dots 0.5s infinite alternate linear;
}

.dot:nth-child(2) {
  animation: dots 1s infinite alternate linear;
}

.dot:nth-child(3) {
  animation: dots 1.5s infinite alternate linear;
}

@media only screen and (min-width: 320px) and (max-width: 575px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .square-content h1 {
    font-size: 18px;
  }
  .loader-position {
    margin-bottom: 400px;
  }
  .square-content {
    height: 1300px;
    display: flex;
    top: 200;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
  @keyframes load {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(245px, 0);
    }
  }

  #bar {
    width: 100px;
    height: 10px;
    background: linear-gradient(to bottom right, #eb0029, #d41837);
    border-radius: 6px;
    /* box-shadow: 0 0 10px #eb0029; */

    animation: load 0.7s infinite alternate ease-in-out;
  }

  @keyframes dots {
    from {
      color: #eb0029;
      transform: translate(0, -10%);
    }
    to {
      color: white;
      transform: translate(0, 0);
    }
  }

  .dot {
    display: inline-block;
    font-size: 250%;
  }

  .dot:nth-child(1) {
    animation: dots 0.5s infinite alternate linear;
  }

  .dot:nth-child(2) {
    animation: dots 1s infinite alternate linear;
  }

  .dot:nth-child(3) {
    animation: dots 1.5s infinite alternate linear;
  }
}
</style>
