<template>
  <footer class="">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-sm-12 col-md-4 text-center">
        <img :src="require('@/images/banorte/powered.png')" class="gh-powered" alt="" />
      </div>
      <div class="col-sm-12 col-md-4 text-md-right text-sm-center">
        <p><a class="text-dark" data-toggle="modal" data-target="#modal-disclaimer">Disclaimer</a></p>
      </div>
    </div>
  </footer>

  <div class="modal fade" id="modal-disclaimer" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title">Riot Games y Supercell</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Riot: Gamers Hunter no es endosado por Riot Games y no refleja la visión y opiniones de Riot Games ni alguien oficialmente involucrado en producir o manejar la propiedad intelectual de Riot Games. Riot Games, y todas las propiedades asociadas son marcas comerciales o marcas comerciales registradas de Riot Games, Inc.
          </p>
          <p>
            Supercell: Este contenido no está afiliado con, endosado, patrocinado, o específicamente aprobado por Supercell, y Supercell no se hace responsable por él. Para más información visita la política de contenido para fans de Supercell:
            <a href="www.supercell.com/fan-content-policy.">www.supercell.com/fan-content-policy.</a>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.gh-powered {
  height: 50px;
  width: auto;
}
</style>

