<template>
  <div v-if="rewardData.reward !== true" style="opacity: 0.5 !important">
    <div class="text-center" style="height: 170px">
      <img :src="require('@/images/banorte/claimgift.png')" class="img-fluid img-reward" style="" alt="" />
    </div>
    <div class="d-flex justify-content-center reward-square-text mb-2">
      <div class="p-2 mt-auto mb-auto text-center">
        <h3 class="reward-title">¡Vuelve pronto!</h3>

        <p class="mb-3 reward-subtitle">Recláma tu recompensa:</p>

        <!-- <p>{{ moment(rewardData.date_last_reward).add(1, "d").format("MM D YYYY, h:mm:ss a") }}</p> -->
        <button class="btn btn-dark btn-block disabled mb-3">{{ moment(rewardData.date_last_reward).locale("es").add(1, "d").startOf("").fromNow() }}</button>

        <span style="font-size: 10px">{{ moment(rewardData.date_last_reward).add(1, "d").format("MM D YYYY, h:mm:ss a") }}</span>
        <p></p>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="text-center" style="height: 170px">
      <img :src="require('@/images/banorte/claimgift.png')" class="img-fluid img-reward" style="" alt="" />
    </div>
    <div class="d-flex justify-content-center reward-square-text mb-2">
      <div class="p-2 mt-auto mb-auto text-center">
        <h3 class="reward-title">¡Nueva Recompensa!</h3>
        <p class="mb-3 reward-subtitle">Reclámala de manera diaria</p>
        <button class="btn btn-card-action2 btn-block mb-3" @click="getDailyReward">Reclamar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

const URLBASE = process.env.VUE_APP_API_URL_BASE;
export default {
  name: "Card Reward",

  data() {
    return {
      rewardData: {},
      reclaimData: {},
    };
  },
  props: { userID: Number },

  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        axios.get(`${URLBASE}/daily_reward/${this.userID}`).then((response) => {
          this.rewardData = response.data.info;
        });
      }
    },
    function() {
      console.log(this.moment);
    },
  },

  created: function () {
    this.moment = moment;
  },

  methods: {
    getDailyReward() {
      axios.get(`${URLBASE}/reclaim_daily_reward/${this.userID}`).then((response) => {
        this.reclaimData = response.data.experience;
        console.log(this.reclaimData);
        successAlertGh("Felicidades", this.reclaimData.msg);
        this.$emit("winXp", this.reclaimData.experience_win);
        this.rewardData.reward = false;
      });
    },
  },
};
</script>

<style scoped>
.reward-image {
  height: 200px;
}

.reward-square-text {
  height: auto;
}

.img-reward {
  width: 100%;
}

.reward-title {
  color: #000;
  font-weight: bold;
}

.reward-subtitle {
  color: #000;
}
@media only screen and (min-width: 320px) and (max-width: 575px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .reward-image {
    height: 200px;
  }

  .reward-square-text {
    height: 160px;
  }

  .reward-title {
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }

  .reward-subtitle {
    color: #000;
    font-size: 12px;
  }
}
</style>
