<template>
  <div class="card--challenge-game" v-if="gameData">
    <transition name="fade">
      <div v-if="showLoader" class="loading-screen">
        <img :src="require(`@/images/banorte/gifs-loaders/${gameData.class}.gif`)" />
        <h5 class="text-white" style="margin-top: 70px">Obteniendo información</h5>
      </div>
    </transition>
    <div>
      <img
        :src="require(`@/images/banorte/challenges_img/${gameData.data_challenge_1.info_challenge.cat_challenge_game.cat_type_category_challenge.image_url}`)"
        loading="lazy"
        class="img-fluid image-card-game"
        style=""
        alt=""
      />
      <div class="top-left">
        <div class="card-text">
          <span class="img-text-over">{{ gameData.notes_game == null ? "" : gameData.notes_game }}</span>
          <span class="img-text-over">
            <img :src="require(`@/images/banorte/icon_asteroid.png`)" width="25" style="padding: 1px 6px" loading="lazy" />{{
              gameData.data_challenge_1.info_challenge.cat_challenge_game.challenge_boost == 0 ? "FÁCIL" : "DIFICIL"
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="card--challenge--media2 pr-3 pl-3 mt-1">
      <div class="container-fluid containerMovil">
        <div class="row mb-2">
          <div class="col-12">
            <div class="d-flex mt-3 mb-2">
              <img class="d-flex img--card-challenge2" alt="48x48" :src="require(`@/images/banorte/gray_icons_games/${gameData.icon}`)" data-holder-rendered="true" style="" />
              <div class="d-flex flex-column justify-content-around">
                <p class="text--card2">
                  {{ gameData.data_challenge_1.info_challenge.cat_challenge_game.description }} <br />
                  <span class="stats-card2 roboBold" :id="spanID"
                    >{{ gameData.data_challenge_1.info_challenge.user_goal }}/{{ gameData.data_challenge_1.info_challenge.goal }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="flex-grow-1">
              <div
                class="progress"
                style="background: rgba(0, 0, 0, 0.25); box-shadow: inset 0 1px 2px rgb(0 0 0 / 25%), 0 1px rgb(255 255 255 / 8%); padding: 1px; border-radius: 30px"
              >
                <div
                  :id="progressID"
                  class="progress-bar progress-card-bar2 animation-bar"
                  role="progressbar"
                  :style="{ width: this.gameData.data_challenge_1.info_challenge.percentage_goal + '%' }"
                  :aria-valuenow="this.gameData.data_challenge_1.info_challenge.percentage_goal"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex text-white pb-3 justify-content-between card-buttons mt-4">
          <div class="pt-lg-2 pt-2 align-self-end">
            <p class="text--coin__card2 align-self-end text-white">+ {{ gameData.data_challenge_1.info_challenge.cat_challenge_game.experience }} XP</p>
          </div>
          <div class="d-flex flex-row justify-content-around">
            <div class="ml-2 mr-3">
              <button class="btn btn-card-action-re2" :data-challenge="gameData.data_challenge_1.info_challenge.id" :data-game="gameData.id" @click="rerollChallenge">
                <i class="fas fa-solid fa-infinity"></i> Reroll
              </button>
            </div>
            <div class="ml-1">
              <button class="btn btn-card-action2" :data-challenge="gameData.data_challenge_1.info_challenge.id" :data-game="gameData.id" @click="refreshChallenge">
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Challenge card",
  props: { gameData: {}, userID: Number },
  data() {
    return {
      showLoader: false,
    };
  },
  computed: {
    progressID() {
      return "progress_" + this.gameData.id;
    },
    spanID() {
      return "span_goal_" + this.gameData.id;
    },
  },

  methods: {
    refreshChallenge() {
      var challengeID = this.gameData.data_challenge_1.info_challenge.id;
      if (challengeID > 0) {
        this.showLoader = true;
        axios
          .get(`${URLBASE}/check_challenge/${this.userID}/${challengeID}`)
          .then((response) => {
            if (!response.data.error) {
              var isComplete = response.data.complete;
              var message = response.data.msg;
              var experience = response.data.experience;
              if (experience && !experience.error) {
                if (experience.experience.completed) {
                  this.$emit("changeUnlockLevel", true);
                } else {
                  this.$emit("changeUnlockLevel", false);
                }
              } else {
                this.$emit("changeUnlockLevel", false);
              }

              axios
                .get(`${URLBASE}/get_challenge/${this.userID}/${this.gameData.id}`)
                .then((response) => {
                  var newChallengeData = response.data.data.data_challenge_1;
                  this.showLoader = false;

                  if (isComplete) {
                    this.gameData.data_challenge_1.info_challenge.percentage_goal = 100;

                    setTimeout(() => {
                      successAlertGh("Challenge completado", message, () => {
                        this.gameData.data_challenge_1 = newChallengeData;
                        this.$emit("winXp", experience.experience_win);
                      });
                    }, 2800);
                  } else {
                    this.gameData.data_challenge_1 = newChallengeData;
                    successAlertGh("Enhorabuena!", "Puntos actualizados correctamente");
                    $(`#${this.progressID}`).addClass("progress-bar-glow");
                    setTimeout(() => {
                      $(`#${this.progressID}`).removeClass("progress-bar-glow");
                    }, 1500);
                  }
                })
                .catch((error) => {
                  errorAlertGh("Ops", error);
                  this.showLoader = false;
                });
            } else {
              this.showLoader = false;
              successAlertGh("Ops", response.data.msg);
            }
          })
          .catch((error) => {
            errorAlertGh("Ops", error);
            this.showLoader = false;
          });
      } else {
        errorAlertGh("Se debe de especificar el challenge", "Intenta nuevamente");
      }
    },

    rerollChallenge() {
      var challengeID = this.gameData.data_challenge_1.info_challenge.id;
      if (challengeID > 0) {
        swal
          .fire({
            title: "¿Deseas hacer reroll a este challenge?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#eb0029",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          })
          .then((result) => {
            this.showLoader = true;
            if (result.value) {
              axios
                .get(`${URLBASE}/reroll_challenge/${this.userID}/${challengeID}`)
                .then((response) => {
                  if (!response.data.error) {
                    axios
                      .get(`${URLBASE}/get_challenge/${this.userID}/${this.gameData.id}`)
                      .then((response) => {
                        if (!response.data.error) {
                          this.gameData.data_challenge_1 = response.data.data.data_challenge_1;
                          this.showLoader = false;
                        } else {
                          errorAlertGh("Ops", response.data.msg);
                          this.showLoader = false;
                        }
                      })
                      .catch((error) => {
                        errorAlertGh("Ops", error);
                        this.showLoader = false;
                      });
                  } else {
                    errorAlertGh("Ooops!", this.dataChallenge.msg);
                    this.showLoader = false;
                  }
                })
                .catch((error) => {
                  errorAlertGh("Algo salio mal", error);
                  this.showLoader = false;
                });
            }
          });
      } else {
        errorAlertGh("Error", "Se debe especificar el challenge");
        this.showLoader = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.image-card-game {
  border-radius: 5px 5px 5px 5px;
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.card-buttons {
  flex-wrap: wrap;
}

.card--challenge-game {
  overflow: hidden;
}
</style>
