<template>
  <div class="modal fade" id="modalGameCounterStrike" tabindex="-1" role="dialog" aria-labelledby="modalGameCounterStrikeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="scene">
            <div class="card-flip card-csgo">
              <div class="card__face card__face--front">
                <img :src="require(`@/images/banorte/challenges_img/101_Plantar_bombas_counter_strike.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
                <div class="card-img-overlay">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card__face card__face--back">
                <img :src="require(`@/images/banorte/help_images/steam.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="pb-2 pt-1 text-center">
              <h3 class="">
                Vincula tu cuenta <button class="button-flip flip-button-csgo"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
              </h3>
              <form id="form-sinc-user-counter-strike" method="POST" @submit.prevent="submitForm" class="text-left form-sync-wrap">
                <div class="form-group">
                  <label for="inputState" style="font-size: 12px">Plataforma</label>
                  <select name="platform_counter" v-model="formCSGO.platform_counter" id="platform_counter" class="form-control">
                    <option value="steam" style="background-color: #18191a" selected>Steam</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="" style="font-size: 12px">Usuario Steam</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="username_counter_strike"
                      v-model="formCSGO.username_counter_strike"
                      name="username_counter_strike"
                      autocomplete="off"
                      class="form-control"
                      placeholder="GamersHunter"
                      required
                    />
                  </div>
                  <input type="hidden" name="game_id_counter_strike" v-model="formCSGO.game_id_counter_strike" id="game_id_counter_strike" />
                  <input type="hidden" name="check_username_counter_strike" v-model="formCSGO.check_username_counter_strike" id="check_username_counter_strike" />
                  <input type="hidden" name="platform_user_identifier_counter" v-model="formCSGO.platform_user_identifier_counter" id="platform_user_identifier_counter" />
                </div>

                <div class="text-center mt-0 pt-0">
                  <button href="#" class="btn button-effect">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Vincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Counter strike modal",
  props: { userID: Number },

  data() {
    return {
      formCSGO: {
        platform_counter: "",
        username_counter_strike: "",
        game_id_counter_strike: 12,
        check_username_counter_strike: 0,
        platform_user_identifier_counter: 0,
        user: this.userID,
      },
    };
  },
  mounted() {
    //Animación de Input
    let card11 = document.querySelector(".card-csgo");

    $("body").on("click", ".flip-button-csgo", function () {
      card11.classList.toggle("is-flipped");
    });
  },

  methods: {
    submitForm() {
      axios
        .post(`${URLBASE}/connect/games/counter_strike`, this.formCSGO)
        .then((response) => {
          let infoChallenge = response.data;
          if (!infoChallenge.error) {
            if (infoChallenge.check_u === 1) {
              this.formCSGO.check_username_counter_strike = infoChallenge.check_u;
              this.formCSGO.platform_user_identifier_counter = infoChallenge.platform_user_identifier;
              setTimeout(() => {
                $("#modalGameCounterStrike").modal("hide");
                this.confirmAlert(infoChallenge.msg);
              }, 1500);
            } else {
              successAlertGh("Vinculación correcta", infoChallenge.msg);
              this.$emit("updateCard");
            }
          } else {
            errorAlertGh("Error", infoChallenge.msg);
            this.formCSGO.check_username_counter_strike = 0;
          }
        })
        .catch((error) => {
          errorAlertGh("Error", "Favor de llenar correctamente los campos");
          this.formCSGO.check_username_counter_strike = 0;
        });
    },

    confirmAlert(message) {
      Swal.fire({
        position: "top",
        title: "Vinculacion Cuenta",
        text: message,

        showCancelButton: true,
        confirmButtonColor: "#eb0029",
        confirmButtonText: "Si, conectar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.submitForm();
        } else {
          this.formCSGO.check_username_counter_strike = 0;
          $("#modalGameCounterStrike").modal("hide");
          this.formCSGO.check_username_counter_strike = "";
          $("#modalGameCounterStrike").reset();
        }
      });
    },
  },
  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        this.formCSGO.user = this.userID;
      }
    },
  },
};
</script>

<style scoped>
</style>
