<template>
  <div class="modal fade" id="modalGameTft" tabindex="-1" role="dialog" aria-labelledby="modalGameTftLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="scene">
            <div class="card-flip card-tft">
              <div class="card__face card__face--front">
                <img :src="require(`@/images/banorte/challenges_img/44_Consigue_nivel5_partidas.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
                <div class="card-img-overlay">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card__face card__face--back">
                <img :src="require(`@/images/banorte/help_images/riot.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="pb-2 pt-1 text-center">
              <h3 class="">
                Vincula tu cuenta <button class="button-flip flip-button-tft"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
              </h3>
              <form id="form-sinc-user-tft" method="POST" @submit.prevent="submitForm" class="text-left form-sync-wrap">
                <div class="form-group">
                  <label for="inputState" style="font-size: 12px">Región</label>
                  <select id="region" v-model="formTFT.region" name="region" class="form-control">
                    <option value="" selected>Seleccione una opción</option>
                    <option value="br1">Brasil (BR)</option>
                    <option value="eun1">Europa Nórdica y Este (EUNE)</option>
                    <option value="euw1">Europa Oeste (EUW)</option>
                    <option value="jp1">Japón (JP)</option>
                    <option value="kr">República de Corea (KR)</option>
                    <option value="la1">Latinoamérica Norte (LAN)</option>
                    <option value="la2">Latinoamérica Sur (LAS)</option>
                    <option value="na1">Norteamérica (NA)</option>
                    <option value="oc1">Oceanía (OCE)</option>
                    <option value="tr1">Rusia (RU)</option>
                    <option value="ru">Turquía (TR)</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="" style="font-size: 12px">Usuario</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="username_tft"
                      v-model="formTFT.username_tft"
                      name="username_tft"
                      autocomplete="off"
                      class="form-control"
                      placeholder="GamersHunter"
                      required
                    />
                  </div>
                  <input type="hidden" name="game_id_tft" v-model="formTFT.game_id_tft" id="game_id_tft" />
                  <input type="hidden" name="check_username_tft" v-model="formTFT.check_username_tft" id="check_username_tft" />
                </div>

                <div class="text-center mt-0 pt-0">
                  <button href="#" class="btn button-effect">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Vincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const URLBASE = process.env.VUE_APP_API_URL_BASE;
export default {
  name: "Tft modal",
  props: { userID: Number },

  data() {
    return {
      formTFT: {
        region: "",
        username_tft: "",
        game_id_tft: 7,
        check_username_tft: 0,
        user: this.userID,
      },
    };
  },
  mounted() {
    //Animación de Input
    let card7 = document.querySelector(".card-tft");

    $("body").on("click", ".flip-button-tft", function () {
      card7.classList.toggle("is-flipped");
    });
  },

  methods: {
    submitForm() {
      axios
        .post(`${URLBASE}/connect/games/tft`, this.formTFT)
        .then((response) => {
          let infoChallenge = response.data;
          if (!infoChallenge.error) {
            if (infoChallenge.check_u === 1) {
              this.formTFT.check_username_tft = infoChallenge.check_u;
              setTimeout(() => {
                $("#modalGameTft").modal("hide");
                this.confirmAlert(infoChallenge.msg);
              }, 1500);
            } else {
              successAlertGh("Vinculación correcta", infoChallenge.msg);
              this.$emit("updateCard");
            }
          } else {
            errorAlertGh("Error", infoChallenge.msg);
            this.formTFT.check_username_tft = 0;
          }
        })
        .catch((error) => {
          errorAlertGh("Error", "Favor de llenar correctamente los campos");
          this.formTFT.check_username_tft = 0;
        });
    },

    confirmAlert(message) {
      Swal.fire({
        position: "top",
        title: "Vinculacion Cuenta",
        text: message,

        showCancelButton: true,
        confirmButtonColor: "#eb0029",
        confirmButtonText: "Si, conectar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.submitForm();
        } else {
          this.formTFT.check_username_tft = 0;
          $("#modalGameTft").modal("hide");
          this.formTFT.check_username_tft = "";
          $("#modalGameTft").reset();
        }
      });
    },
  },
  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        this.formTFT.user = this.userID;
      }
    },
  },
};
</script>

<style scoped>
</style>
