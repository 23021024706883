<template>
  <div class="modal fade" id="modalGameValorant" tabindex="-1" role="dialog" aria-labelledby="modalGameLegendsOfRunaterraLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="scene">
            <div class="card-flip card-lor">
              <div class="card__face card__face--front">
                <img :src="require(`@/images/banorte/challenges_img/109_Jugar_partidas_valorant.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
                <div class="card-img-overlay">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card__face card__face--back">
                <img :src="require(`@/images/banorte/help_images/riot.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="pb-2 pt-1 text-center">
              <h3 class="">
                Selecciona tu región <button class="button-flip flip-button-lor"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
              </h3>
              <form id="form-sinc-valorant" method="POST" @submit.prevent="submitForm" class="text-left form-sync-wrap">
                <div class="form-group">
                  <label for="inputState" style="font-size: 12px">Región</label>
                  <select id="region" v-model="formValorant.region" name="region" class="form-control">
                    <option value="" selected>Seleccione una opción</option>
                    <option value="ap">AP</option>
                    <option value="br">BR</option>
                    <option value="eu">EU</option>
                    <option value="kr">KR</option>
                    <option value="latam">LATAM</option>
                    <option value="na">NA</option>
                  </select>
                </div>
                <input type="hidden" name="game_id_val" v-model="formValorant.game_valorant_id" id="game_id_val" />
                <div class="text-center mt-0 pt-0">
                  <button class="btn button-effect">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Vincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Valorant modal",
  props: { userID: Number },

  data() {
    return {
      formValorant: {
        region: "",
        game_valorant_id: 13,
        user: this.userID,
      },
    };
  },
  mounted() {},

  methods: {
    submitForm() {
      axios
        .post(`${URLBASE}/connect/games/valorant/set_region`, this.formValorant)
        .then((response) => {
          let infoChallenge = response.data;
          if (!infoChallenge.error) {
            $("#modalGameValorant").modal("hide");
            successAlertGh("Correcto", infoChallenge.msg);
            this.$emit("updateCard");
          } else {
            errorAlertGh("Error", infoChallenge.msg);
          }
        })
        .catch((error) => {
          errorAlertGh("Error", "Favor de llenar correctamente los campos");
        });
    },
  },
  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        this.formValorant.user = this.userID;
      }
    },
  },
};
</script>

<style scoped>
</style>
