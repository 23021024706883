<template>
  <div class="modal fade" id="modalGameBrawlStars" tabindex="-1" role="dialog" aria-labelledby="modalGameBrawlStarsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="scene">
            <div class="card-flip card-bs">
              <div class="card__face card__face--front">
                <img :src="require(`@/images/banorte/challenges_img/11_Ganar_partidas_Brawl_Stars.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
                <div class="card-img-overlay">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card__face card__face--back">
                <img :src="require(`@/images/banorte/help_images/brawl_stars.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="pb-2 pt-1 text-center">
              <h3 class="">
                Vincula tu cuenta <button class="button-flip flip-button-bs"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
              </h3>
              <form id="form-sinc-user-brawl-stars" @submit.prevent="submitForm" class="text-left form-sync-wrap">
                <div class="form-group">
                  <label for="" style="font-size: 12px">Usuario</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="username_supercell_brawl_stars"
                      v-model="formBS.username_supercell_brawl_stars"
                      name="username_supercell_brawl_stars"
                      autocomplete="off"
                      class="form-control"
                      placeholder="#A1B2C3D4E"
                      required
                    />
                  </div>
                  <input type="hidden" name="game_id_brawl_stars" id="game_id_brawl_stars" v-model="formBS.game_id_brawl_stars" />
                  <input type="hidden" name="check_username_brawl_stars" id="check_username_brawl_stars" v-model="formBS.check_username_brawl_stars" />
                  <input type="hidden" v-model="formBS.user" />
                </div>

                <div class="text-center mt-0 pt-0">
                  <button href="#" class="btn button-effect">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Vincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Brawl stars modal",
  props: { userID: Number },

  data() {
    return {
      formBS: {
        username_supercell_brawl_stars: "",
        game_id_brawl_stars: 4,
        check_username_brawl_stars: 0,
        user: this.userID,
      },
    };
  },
  mounted() {
    //Animación de Input
    let card4 = document.querySelector(".card-bs");

    $("body").on("click", ".flip-button-bs", function () {
      card4.classList.toggle("is-flipped");
    });
  },
  methods: {
    submitForm() {
      axios
        .post(`${URLBASE}/connect/games/brawl_stars`, this.formBS)
        .then((response) => {
          let infoChallenge = response.data;

          if (!infoChallenge.error) {
            if (infoChallenge.check_u === 1) {
              this.formBS.check_username_brawl_stars = infoChallenge.check_u;
              $(".button-prevent-multiple-submits2").attr("disabled", true);
              $(".spinner").show();
              $("body").css("cursor", "wait");
              setTimeout(() => {
                $(document.body).css({ cursor: "default" });
                $("#modalGameBrawlStars").modal("hide");
                this.confirmAlert(infoChallenge.msg);
              }, 1500);
            } else {
              successAlertGh("Vinculación correcta", infoChallenge.msg);
              this.$emit("updateCard");
            }
          } else {
            errorAlertGh("Error", infoChallenge.msg);
            this.formBS.check_username_brawl_stars = 0;
          }
        })
        .catch((error) => {
          errorAlertGh("Error", "Favor de llenar correctamente los campos");
          this.formBS.check_username_brawl_stars = 0;
        });
    },

    confirmAlert(message) {
      Swal.fire({
        position: "top",
        title: "Vinculacion Cuenta",
        text: message,

        showCancelButton: true,
        confirmButtonColor: "#eb0029",
        confirmButtonText: "Si, conectar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.submitForm();
        } else {
          this.formBS.check_username_brawl_stars = 0;
          $("#modalGameBrawlStars").modal("hide");
          this.formBS.check_username_brawl_stars = "";
          $("#modalGameBrawlStars").reset();
        }
      });
    },
  },
  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        this.formBS.user = this.userID;
      }
    },
  },
};
</script>

<style scoped>
</style>
