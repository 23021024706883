<template>
  <transition name="fade">
    <Loader v-if="showLoader" ref="loader" />
  </transition>

  <div class="container-fluid main-body">
    <div class="row d-block d-sm-none mt-4">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <!-- Mobile game list -->
        <div class="scroll-horizontal">
          <ul class="nav nav-pills mb-3" id="mv-pills-tabContent" role="tablist" style="flex-wrap: nowrap">
            <TabGameMobile v-for="(data, index) in gameData" :index="index" :key="data.id" :id="data.class" :data="data"></TabGameMobile>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="card card-list-games">
          <div class="card-height">
            <!-- Desktop game list -->
            <div class="sync__list">
              <div class="card--wrap">
                <div class="d-none d-sm-block">
                  <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <TabGameDesktop v-for="(data, index) in gameData" :index="index" :key="data.id" :id="data.class" :data="data"></TabGameDesktop>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="row pb-3">
          <div class="col-sm-12 col-md-6 challenge-size" style="">
            <div class="card challenge-card">
              <div class="tab-content" id="v-pills-tabContent">
                <div
                  v-for="data in gameData"
                  :data="data"
                  :key="data.id"
                  :id="`pills-` + data.class"
                  class="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="v-pills-1-tab"
                  v-bind:class="{ active: data.first_tab, show: data.tab_show }"
                >
                  <CardChallengeLink
                    v-if="data.error == true"
                    :gameClass="data.class"
                    :gameName="data.name"
                    :gameModal="data.modal"
                    :gamePlatforms="data.platforms"
                    :gameID="data.id"
                    :token="token1"
                  ></CardChallengeLink>
                  <CardChallengeError
                    v-else-if="data.data_challenge_1.error == true"
                    :errorMessage="data.data_challenge_1.msg"
                    :gameID="data.id"
                    :gameModal="data.modal"
                    :dataChallenge="data.data_challenge_1"
                    :userID="userID"
                    @update-card="updateCard"
                  ></CardChallengeError>
                  <CardChallenge v-else :gameData="data" :userID="userID" @win-xp="winXp" @changeUnlockLevel="changeUnlockLevel"></CardChallenge>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-none d-sm-block">
            <div class="card card-reward-size">
              <CardReward :userID="userID" @win-xp="winXp" />
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-12 progress-size">
            <div class="card movil-card">
              <CardProgress
                :userID="userID"
                :xp="xp"
                @reset-xp="resetXp"
                :userGoal="userGoal"
                :goal="goal"
                :percentageGoal="percentageGoal"
                :unlockGasto="unlockGasto"
                :unlockLevel="unlockLevel"
                @changeUnlockLevel="changeUnlockLevel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <Disclaimer />
      </div>
    </div>
  </div>

  <ModalApexLegends :userID="userID" @update-card="updateCard" />
  <ModalBrawlStars :userID="userID" @update-card="updateCard" />
  <ModalClashOfClans :userID="userID" @update-card="updateCard" />
  <ModalClashRoyale :userID="userID" @update-card="updateCard" />
  <ModalCounterStrike :userID="userID" @update-card="updateCard" />
  <ModalFortinte :userID="userID" @update-card="updateCard" />
  <ModalLol :userID="userID" @update-card="updateCard" />
  <ModalLor :userID="userID" @update-card="updateCard" />
  <ModalPubg :userID="userID" @update-card="updateCard" />
  <ModalTft :userID="userID" @update-card="updateCard" />
  <ModalValorant :userID="userID" @update-card="updateCard" />
  <ModalWarzone :userID="userID" @update-card="updateCard" />
</template>

<script>
import axios from "axios";
import Loader from "./components/Loader.vue";
import Banner from "./components/Banner.vue";
import Disclaimer from "./components/DisclaimerBar.vue";
import CardChallenge from "./components/challenges/Card.vue";
import CardChallengeError from "./components/challenges/Error.vue";
import CardChallengeLink from "./components/challenges/Link.vue";
import CardReward from "./components/CardReward.vue";
import CardProgress from "./components/CardProgress.vue";
import TabGameDesktop from "./components/games/TabDesktop.vue";
import TabGameMobile from "./components/games/TabMobile.vue";
import ModalApexLegends from "./components/modals/Apex_legends.vue";
import ModalBrawlStars from "./components/modals/Brawl_stars.vue";
import ModalClashOfClans from "./components/modals/Clash_of_clans.vue";
import ModalClashRoyale from "./components/modals/Clash_royale.vue";
import ModalCounterStrike from "./components/modals/Counter_strike.vue";
import ModalFortinte from "./components/modals/Fortnite.vue";
import ModalLol from "./components/modals/Lol.vue";
import ModalLor from "./components/modals/Lor.vue";
import ModalPubg from "./components/modals/Pubg.vue";
import ModalTft from "./components/modals/Tft.vue";
import ModalValorant from "./components/modals/Valorant";
import ModalWarzone from "./components/modals/Warzone.vue";
const URLBASE = process.env.VUE_APP_API_URL_BASE;
const URLPROJECT = process.env.VUE_APP_WEB_URL_MAIN;
const IDCHAT = process.env.VUE_APP_INTERCOM_APP_ID;
const COMPANY = process.env.VUE_APP_INTERCOM_APP_NAME;
const COMPANYID = process.env.VUE_APP_INTERCOM_APP_ID;
export default {
  name: "App",
  components: {
    Loader,
    Banner,
    Disclaimer,
    TabGameDesktop,
    TabGameMobile,
    CardChallenge,
    CardChallengeError,
    CardChallengeLink,
    CardReward,
    CardProgress,
    ModalApexLegends,
    ModalBrawlStars,
    ModalClashOfClans,
    ModalClashRoyale,
    ModalCounterStrike,
    ModalFortinte,
    ModalLol,
    ModalLor,
    ModalPubg,
    ModalTft,
    ModalValorant,
    ModalWarzone,
  },
  data() {
    return {
      showLoader: true,
      gameData: {},
      userID: null,
      xp: 0, // XP won on CardChallenge, relay to CardProgress
      token1: null,
      goal: 0,
      userGoal: 0,
      percentageGoal: 0,
      unlockLevel: false,
      unlockGasto: false,
    };
  },
  mounted() {
    const location = window.location.toString();
    const url = new URL(location);
    const token = url.searchParams.get("token") || localStorage.getItem("storageToken");

    const receiveMessage = (event) => {
      const url = new URL(event.data);
      const code = url.searchParams.get("code");
      const error_get = url.searchParams.get("error");
      console.log(event);
      if (!error_get && code) {
        console.log(code);
        this.verifyCode(code);
      } else if (error_get) {
        errorAlertGh("Error", error_get);
      }
    };

    window.addEventListener("message", (event) => receiveMessage(event), false);

    const params = window.location.href;
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage(params);
      // close the popup
      window.close();
      return;
    }

    this.initialize(token);

    // this.$intercom.boot({
    //   user_id: this.userID,
    // });
    // this.$intercom.show();
  },
  watch: {
    async gameData(nuevoValor, valorAnterior) {
      console.log("El nombre pasó de ser '%s' a '%s'", valorAnterior, nuevoValor);
    },

    // userIdInfo(user_id) {
    //   this.$intercom.update(user_id);
    // },
  },
  methods: {
    verifyCode(code) {
      let userID = localStorage.getItem("time");
      axios
        .post(`${URLBASE}/connect/games/valorant`, {
          code: code,
          user: userID,
          game_id_valorant: 13,
          redirect_uri: URLPROJECT + "/",
        })
        .then((response) => {
          console.log(response.data);
          if (!response.data.error) {
            successAlertGh("Vinculación correcta", response.data.msg);
          } else {
            errorAlertGh("Error Valorant", response.data.msg);
          }
        })
        .catch((error) => {
          errorAlertGh("Error Valorant", "Ha ocurrido un error en el servidor");
          console.log(error);
        });
    },
    async initialize(token) {
      //alert(token);
      this.token1 = token;
      // Validate user
      try {
        const result = await axios.post(`${URLBASE}/validate`, { token });
        if (!result.data.error) {
          this.userID = result.data.info.id;
          this.name = result.data.info.name;
          this.createdDate = result.data.info.created_at;
          localStorage.setItem("storageToken", token); //Asignando variable storage
          localStorage.setItem("time", this.userID); //Asignando variable storage
          localStorage.setItem("name", this.name); //Asignando variable storage
          localStorage.setItem("intercom", IDCHAT);
          localStorage.setItem("company", COMPANY);
          localStorage.setItem("companyId", COMPANYID);
          localStorage.setItem("created_at", this.createdDate);
          document.getElementById("userID").value = this.userID; // TODO: replace this mechanism?
          // TODO: leer el gasto y asignarlo a la barra
          this.goal = result.data.gasto["meta"];
          this.userGoal = result.data.gasto["user_meta"];
          this.updateCardGasto(this.goal, this.userGoal);
        } else {
          errorAlertGh("Error", result.data.msg);
        }
      } catch (error) {
        // NOTE: .response can be undefined in case of network error
        console.log(error);
        if (error.response) {
          if (error.response.status == 422) {
            errorAlertGh("Error", "Expected user token");
          } else {
            errorAlertGh("Error", "Ocurrio un error en el servidor");
          }
        } else {
          errorAlertGh("Error", "Error de conexión", "Reintentar", this.initialize);
        }
      }
      // Load challenges if user
      if (this.userID) {
        try {
          const result = await axios.get(`${URLBASE}/get_games_challenges_user/${this.userID}`);
          result.data.info.forEach((game, id) => {
            this.gameData[game.class] = game;
          });
          this.showLoader = false;
        } catch (error) {
          errorAlertGh("Ooops", "No se ha podido cargar tus juegos y challenges");
        }
      }
    },
    winXp(xp) {
      this.xp = xp;
    },
    resetXp() {
      this.xp = 0;
    },
    changeUnlockLevel(status) {
      this.unlockLevel = status;
    },
    async updateCard() {
      const result = await axios.get(`${URLBASE}/get_games_challenges_user/${this.userID}`);
      result.data.info.forEach((game, id) => {
        this.gameData[game.class] = game;
      });
    },

    async updateCardGasto(goal, userGoal) {
      this.percentageGoal = (userGoal * 100) / goal;
      if (userGoal >= goal) {
        this.unlockGasto = true;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.square-red {
  border: 1px solid red;
}

.square-blue {
  border: 1px solid blue;
}
</style>
