<template>
  <div class="modal fade" id="modalGamePubg" tabindex="-1" role="dialog" aria-labelledby="modalGamePubgLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="scene">
            <div class="card-flip card-pubg">
              <div class="card__face card__face--front">
                <img :src="require(`@/images/banorte/challenges_img/77_Tomar_boost_PUBG.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
                <div class="card-img-overlay">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="card__face card__face--back">
                <img :src="require(`@/images/banorte/help_images/steam.jpg`)" class="img-fluid" style="border-radius: 10px 10px 0 0" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="pb-2 pt-1 text-center">
              <h3 class="">
                Vincula tu cuenta <button class="button-flip flip-button-pubg"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
              </h3>
              <form id="form-sinc-user-pubg" method="POST" @submit.prevent="submitForm" class="text-left form-sync-wrap">
                <div class="form-group">
                  <label for="inputState" style="font-size: 12px">Plataforma</label>
                  <select name="platform_pubg" v-model="formPUBG.platform_pubg" id="platform_pubg" class="form-control">
                    <option value="">Seleccione una opción</option>
                    <option value="steam">Steam</option>
                    <option value="xbox">Xbox</option>
                    <option value="psn">PlayStation</option>
                    <option value="stadia">Stadia</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="" style="font-size: 12px">Usuario</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="username_pubg"
                      v-model="formPUBG.username_pubg"
                      name="username_pubg"
                      autocomplete="off"
                      class="form-control"
                      placeholder="GamersHunter"
                      required
                    />
                  </div>
                  <input type="hidden" name="game_id_pubg" v-model="formPUBG.game_id_pubg" id="game_id_pubg" />

                  <input type="hidden" name="check_username_pubg" v-model="formPUBG.check_username_pubg" id="check_username_pubg" />
                  <input type="hidden" name="player_id" v-model="formPUBG.player_id" id="player_id" />
                </div>

                <div class="text-center mt-0 pt-0">
                  <button href="#" class="btn button-effect">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Vincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Pubg modal",
  props: { userID: Number },

  data() {
    return {
      formPUBG: {
        platform_pubg: "",
        username_pubg: "",
        game_id_pubg: 8,
        check_username_pubg: 0,
        player_id: 0,
        user: this.userID,
      },
    };
  },
  mounted() {
    //Animación de Input
    let card8 = document.querySelector(".card-pubg");

    $("body").on("click", ".flip-button-pubg", function () {
      card8.classList.toggle("is-flipped");
    });
  },

  methods: {
    submitForm() {
      axios
        .post(`${URLBASE}/connect/games/pubg`, this.formPUBG)
        .then((response) => {
          let infoChallenge = response.data;
          if (!infoChallenge.error) {
            if (infoChallenge.check_u === 1) {
              this.formPUBG.check_username_pubg = infoChallenge.check_u;
              this.formPUBG.player_id = infoChallenge.player_id;
              setTimeout(() => {
                $("#modalGamePubg").modal("hide");
                this.confirmAlert(infoChallenge.msg);
              }, 1500);
            } else {
              successAlertGh("Vinculación correcta", infoChallenge.msg);
              this.$emit("updateCard");
            }
          } else {
            errorAlertGh("Error", infoChallenge.msg);
            this.formPUBG.check_username_pubg = 0;
          }
        })
        .catch((error) => {
          errorAlertGh("Error", "Favor de llenar correctamente los campos");
          this.formPUBG.check_username_pubg = 0;
        });
    },

    confirmAlert(message) {
      Swal.fire({
        position: "top",
        title: "Vinculacion Cuenta",
        text: message,

        showCancelButton: true,
        confirmButtonColor: "#eb0029",
        confirmButtonText: "Si, conectar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.submitForm();
        } else {
          this.formPUBG.check_username_pubg = 0;
          $("#modalGamePubg").modal("hide");
          this.formPUBG.check_username_pubg = "";
          $("#modalGamePubg").reset();
        }
      });
    },
  },
  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        this.formPUBG.user = this.userID;
      }
    },
  },
};
</script>

<style scoped>
</style>
