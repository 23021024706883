<template>
  <li class="nav-item">
    <a
      style="background: rgba(255, 255, 255, 1); border: 1px solid #dadcd9"
      class="nav-link"
      v-bind:class="{ active: isActive, show: isShow }"
      role="tab"
      data-toggle="pill"
      :id="'v-pills-' + id + '-tab'"
      :href="'#pills-' + id"
      :aria-controls="'pills-' + id"
    >
      <img :src="require(`@/images/banorte/movil-icons/${id}.png`)" class="img-icon" alt="" />
    </a>
  </li>
</template>

<script>
export default {
  name: "Mobile game tab",
  props: ["index", "id", "data"],
  computed: {
    isActive() {
      return this.data.first_tab == "active";
    },
    isShow() {
      return this.data.active == 1;
    },
  },
};
</script>

<style scoped>
</style>
