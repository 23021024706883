<template>
  <div class="card" style="border: none; background-color: #fff">
    <img :src="require(`@/images/banorte/sync_square/${gameClass}_on.png`)" class="img-fluid square-img-link" style="border-radius: 10px" alt="" />
    <div class="card-img-overlay d-flex align-items-center">
      <div class="container-fluid">
        <div class="row" style="">
          <div class="col-4" style=""></div>
          <div class="col-8">
            <div>
              <p class="m-0 mb-2" id="">
                <span v-for="platform in gamePlatforms" :key="platform.id">
                  <img :src="require(`@/images/banorte/black_icons/${platform.name_image}`)" height="35" class="pr-2" />
                </span>
              </p>
            </div>
            <h3 class="m-0 roboBold card-title-onboarding">{{ gameName }}</h3>
            <div v-if="gameID === 13">
              <div class="mt-3">
                <a class="btn btn-sync-action btn-lg" @click="popUpCenter()"> Vincular </a>
                <!-- <button class="btn btn-sync-action btn-lg" data-toggle="modal" :data-target="'#' + gameModal">Vincular</button> -->
              </div>
            </div>
            <div v-else>
              <div class="mt-3">
                <button class="btn btn-sync-action btn-lg" data-toggle="modal" :data-target="'#' + gameModal">Vincular</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const location = window.location.toString();
const url = new URL(location);
const URLPROJECT = process.env.VUE_APP_WEB_URL_MAIN;
export default {
  name: "Link card",
  props: ["gameClass", "gameName", "gameModal", "gamePlatforms", "gameID", "token"],
  data() {
    return {
      urlRoot: URLPROJECT,
      urlNow: url,
      urlRiot: "https://www.google.com.mx/?hl=es-419",
      // urlRiot: "https://auth.riotgames.com/authorize?client_id=gamershunter&redirect_uri=" + urlRoot + "/&response_type=code&scope=openid+offline_access",
    };
  },
  methods: {
    popUpCenter() {
      let url = "https://auth.riotgames.com/authorize?client_id=gamershunter&redirect_uri=" + URLPROJECT + "/&response_type=code&scope=openid+offline_access";
      let title = "Riot";
      let w = 960;
      let h = 500;
      let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
      let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

      let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      let left = width / 2 - w / 2 + dualScreenLeft;
      let top = height / 2 - h / 2 + dualScreenTop;
      let newWindow = window.open(url, title, "scrollbars=yes, width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);

      // Puts focus on the newWindow
      if (window.focus) {
        newWindow.focus();
      }
    },
  },
};
</script>

<style>
</style>