<template>
  <div v-if="progressData.error === false">
    <div class="row p-3 size-progress-card mt-3">
      <div class="col-sm-12 col-md-6">
        <h4 class="pt-2 pl-3 text-dark title-card">Progreso</h4>
        <div class="row mb-3">
          <div class="col-2">
            <!-- <img :src="require('@/images/banorte/levels/black/1.png')" class="level-image" id="levelNow" alt="" /> -->
            <img :src="progressData.experience.file_range" class="level-image" id="" alt="" />
          </div>
          <div class="col-7 ml-4 mt-auto mb-2">
            <h5 class="text-uppercase title-progress" id="">
              {{ progressData.experience.title }} <button type="button" class="button-flip" data-toggle="modal" data-target="#modal-explain-xp"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
            </h5>

            <p class="subtitle-progress">
              Experiencia: <span class="span-progress">{{ progressData.experience.user_goal }}</span> /
              <span class="span-progress" id="">{{ progressData.experience.experience }}</span>
            </p>
          </div>

          <div class="floatScore">
            <h5 class="" id="scorePop" style=""></h5>
          </div>

          <div class="col-12">
            <div class="flex-grow-1">
              <div class="progress custom-bar-level" style="">
                <div
                  id="progressBarLevel"
                  class="progress-bar progress-card-bar2 animation-bar"
                  role="progressbar"
                  :style="'width:' + progressData.experience.user_experience + '%'"
                  aria-valuenow="{{progressData.experience.user_experience}}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <img :src="require('@/images/banorte/levels/black/1.png')" class="level-image" alt="" />
          </div>
          <div class="col ml-4 mt-auto mb-2">
            <h5 class="text-uppercase title-progress">
              Reto Banorte <button type="button" class="button-flip" data-toggle="modal" data-target="#modal-explain-custom"><i class="far fa-question-circle" style="font-size: 18px"></i></button>
            </h5>
            <p class="subtitle-progress">
              Transacciones: <span class="span-progress">{{ this.userGoal }} / {{ this.goal }}</span>
            </p>
          </div>

          <div class="col-12">
            <div class="flex-grow-1">
              <div class="progress custom-bar-level">
                <div
                  id="progress_"
                  class="progress-bar progress-card-bar2 animation-bar"
                  role="progressbar"
                  :style="'width:' + this.percentageGoal + '%'"
                  aria-valuenow="{{this.percentageGoal}}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="color-card-prize text-center">
          <div class="row mt-3" v-if="this.unlockGasto && levelComplete">
            <div class="offset-2 col-4">
              <div class="bg-item-card_active">
                <img :src="progressData.experience.file_bounty" class="img-fluid bounty-img-size" alt="imagen premio" />
              </div>
            </div>

            <div class="col-4" v-if="progressData.next_experience">
              <div class="bg-item-card_active">
                <img :src="progressData.next_experience.file_range" class="img-fluid bounty-img-size" alt="imagen premio" />
              </div>
            </div>

            <div class="offset-2 col-8 mt-4">
              <!-- <h3 class="title-progress mb-3">¡Recompensa de nivel!</h3> -->
              <button class="btn btn-card-action2 btn-block" @click="getLevelReward">Reclamar</button>
            </div>
          </div>

          <div class="row mt-3" v-else>
            <div class="offset-2 col-4">
              <div class="bg-item-card_active" style="opacity: 0.5 !important">
                <img :src="progressData.experience.file_bounty" class="img-fluid bounty-img-size" alt="imagen premio" />
              </div>
            </div>

            <div class="col-4" v-if="progressData.next_experience">
              <div class="bg-item-card_active" style="opacity: 0.5 !important">
                <img :src="progressData.next_experience.file_range" id="giftLevel" class="img-fluid bounty-img-size" alt="imagen premio" />
              </div>
            </div>
            <div class="offset-2 col-8 mt-4">
              <div class="btn btn-dark btn-block disabled btn-disabled">No disponible</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h1 class="text-center mt-5">{{ progressData.msg }}</h1>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg" id="modal-explain-xp" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title">Progreso de nivel de experiencia</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Este es tu progreso total de experiencia. Llenalo completando retos!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg" id="modal-explain-custom" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title">Progreso de reto Banorte</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Este es tu progreso total de transacciones Banorte. Cada transaccion de mas de 500 pesos se vera contabilizada aquí despues de 24 horas.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const URLBASE = process.env.VUE_APP_API_URL_BASE;
export default {
  name: "Card Progress",

  data() {
    return {
      progressData: {},
      nextLevelData: {},
      levelComplete: false,
    };
  },

  props: { userID: Number, xp: Number, goal: Number, userGoal: Number, percentageGoal: Number, unlockGasto: Boolean, unlockLevel: Boolean },

  watch: {
    userID(newValue, oldValue) {
      if (newValue && oldValue === null) {
        axios.get(`${URLBASE}/experience/${this.userID}`).then((response) => {
          this.progressData = response.data;
          if (response.data.experience.completed === 1) {
            this.$emit("changeUnlockLevel", true);
          } else {
            this.$emit("changeUnlockLevel", false);
          }
        });
      }
    },
    xp(newValue, oldValue) {
      if (newValue > 0) {
        $("#scorePop").addClass("scorePlus");
        $("#scorePop").text(`+ ${newValue} XP`);
        setTimeout(() => {
          $("#scorePop").removeClass("scorePlus");
          $("#scorePop").text("");
        }, 3300);
        axios.get(`${URLBASE}/experience/${this.userID}`).then((response) => {
          this.progressData = response.data;
        });
        this.$emit("resetXp");
      }
    },

    unlockLevel(newValue, oldValue) {
      if (newValue) {
        this.levelComplete = true;
      }
    },
  },

  methods: {
    getLevelReward() {
      axios.get(`${URLBASE}/reclaim_level/${this.userID}/${this.progressData.experience.id}`).then((response) => {
        console.log(response);
        if (!response.data.error) {
          successAlertGh("Felicidades", response.data.msg);
          axios.get(`${URLBASE}/experience/${this.userID}`).then((response) => {
            this.progressData = response.data;
            this.levelComplete = false;
            this.$emit("changeUnlockLevel", false);
          });
        } else {
          errorAlertGh("Ops", response.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.bounty-img-size {
  width: 112px;

  /* min-width: 111px;
  max-width: 112px; */
}
.scorePlus {
  animation: shadow 4s infinite;
  color: #eb0029;
  font-size: 22px;
  margin-top: 70px;
}

@keyframes shadow {
  0% {
    transform: translateY(0);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: translateY(-40px);
    opacity: 0;
  }

  0% {
    transform: translateY(0);
    opacity: 0;
  }
}
.size-progress-card {
  height: 100%;
}

.level-image {
  height: 100px;
  width: 100px;
}

.title-progress {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}

.subtitle-progress {
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
}

.span-progress {
  color: #eb0029;
}

.custom-bar-level {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 25%), 0 1px rgb(255 255 255 / 8%);
  padding: 1px;
}

.color-card-prize {
  border-radius: 15px;
  background: #dadcd9;
  height: 100%;
  padding: 20px;
}

.bg-item-card_inactive {
  background: #eb0029;
  border-radius: 15px;
}

.bg-item-card_active {
  background: #eb0029;
  border-radius: 15px;
  padding: 30px 10px;
}

@media only screen and (min-width: 320px) and (max-width: 575px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .level-image {
    height: 80px;
    width: 80px;
  }

  .title-progress {
    font-weight: 600;
    color: #000;
    font-size: 14px;
  }

  .subtitle-progress {
    color: #000;
    font-size: 12px;
  }

  .scorePlus {
    animation: shadow 4s infinite;
    color: #eb0029;
    font-size: 12px;
    margin-top: -10px;
    margin-left: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .title-progress {
    font-weight: 600;
    color: #000;
    font-size: 10;
  }

  .subtitle-progress {
    color: #000;
    font-size: 10px;
    text-transform: uppercase;
  }
  .title-card {
    font-size: 14px;
  }
  .level-image[data-v-d881a1e6] {
    height: 70px;
    width: 70px;
  }
}
</style>
