<template>
  <a
    class="nav-link"
    v-bind:class="{ active: isActive, show: isShow }"
    role="tab"
    data-toggle="pill"
    :id="'v-pills-' + id + '-tab'"
    :href="'#pills-' + id"
    :aria-controls="'pills-' + id"
  >
    <img :src="require(`@/images/banorte/games_list/${id}_on.jpg`)" class="img-fluid" alt="" />
  </a>
</template>

<script>
export default {
  name: "Desktop game tab",
  props: ["index", "id", "data"],
  computed: {
    isActive() {
      return this.data.first_tab == "active";
    },
    isShow() {
      return this.data.active == 1;
    },
  },
};
</script>

<style scoped>
</style>
