<template>
  <div class="p-1" style="width: 100%">
    <img src="https://image.shutterstock.com/image-vector/online-reward-gift-box-vector-600w-1937097007.jpg" class="img-fluid p-3" style="border-radius: 15px; height: 485px" alt="" />
  </div>
</template>
<script>
export default {
  name: "Banner",
  mounted() {
    console.log(this);
  },
};
</script>

<style scoped>
</style>