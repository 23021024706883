<template>
  <div v-if="dataChallenge.type === 1">
    <div class="" style="border: none">
      <img :src="require(`@/images/banorte/errorcard.png`)" class="img-fluid square-img" style="border-radius: 10px" alt="" />
      <div class="card-img-overlay d-flex justify-content-center">
        <div class="d-flex text-white align-items-end">
          <div class="p-2 flex-fill text-center">
            <div class="">
              <div></div>
              <div class="">
                <h5 class="m-0">
                  <span class="card-title-onboarding--sub">{{ errorMessage }}</span>
                </h5>
              </div>
              <div class="mt-3">
                <button class="btn btn-sync-action btn-request-challenge" style="background: #242833" data-game="${data_game.id}" @click="getNewChallenge">Solicitar otro</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card" style="border: none; background-color: #242833">
      <img :src="require(`@/images/banorte/valorant.jpg`)" class="img-fluid" style="border-radius: 10px" alt="" />
      <div class="card-img-overlay d-flex justify-content-start">
        <div class="d-flex text-white align-items-center">
          <div class="p-2 flex-fill text-left">
            <div class="ajust-text">
              <div class="">
                <h3 class="m-0 roboBold card-title-onboarding">Seleccione una región</h3>
              </div>
              <button class="btn btn-sync-action btn-lg" data-toggle="modal" :data-target="'#' + gameModal">Región</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const URLBASE = process.env.VUE_APP_API_URL_BASE;

export default {
  name: "Error challenge card",
  props: ["errorMessage", "userID", "gameID", "dataChallenge", "gameModal"],
  methods: {
    getNewChallenge() {
      axios
        .get(`${URLBASE}/get_challenge/${this.userID}/${this.gameID}`)
        .then((response) => {
          var newChallengeData = response.data.data.data_challenge_1;
          this.showLoader = false;
          successAlertGh("Actuzalizado con éxito");
          this.$emit("updateCard");
        })
        .catch((error) => {
          errorAlertGh("Algo salió mal", error);
          this.showLoader = false;
        });
    },
  },
};
</script>

<style>
</style>
